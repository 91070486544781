import { ChakraProvider } from '@chakra-ui/react'
import store from '@core/app/store/store'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import '../../index.css'
import theme from '../../theme.js'
import { PageUnknown } from './Unknown'

import '@core/utils/i18n'

const app = document.getElementById('root')
const root = createRoot(app!)
root.render(
    <>
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <PageUnknown />
            </ChakraProvider>
        </Provider>
    </>
)
