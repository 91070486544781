import { Flex, Image, Text } from '@chakra-ui/react'
import image from '@page/unknown/assets/images/unknown.svg'
import { useTranslation } from 'react-i18next'

export const PageUnknown = () => {
    const { t } = useTranslation()
    return (
        <Flex minH={'100vh'} flexDirection={'column'} bg={'bg.1'}>
            <Flex
                as={'main'}
                flexDirection={'column'}
                w={'full'}
                alignItems={'center'}
                justifyContent={'center'}
                h={'full'}
                flex={1}
            >
                <Flex flexDirection={'column'} alignItems={'center'}>
                    <Image src={image} />
                    <Text
                        fontSize={'2xl'}
                        fontWeight={'semibold'}
                        color={'text.main'}
                        maxW={'218px'}
                        textAlign={'center'}
                        mt={6}
                    >
                        {t('6919')}
                    </Text>
                    <Text maxW={'301px'} textAlign={'center'} mt={3}>
                        {t('6920')}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
